import React, { useEffect, useState } from "react";
import Header from "../components/UserHeader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { GraphicsService } from "../service/GraphicsService";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { AnalyticsService } from "../service/AnalyticsService";
import moment from "moment";
import randomColor from "randomcolor";
import { ru } from "date-fns/locale/ru";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Graphics = () => {
  const filtersInitialValues = {
    date: {
      date_from: dayjs().subtract(2, "month").format("YYYY-MM"),
      date_to: dayjs().format("YYYY-MM"),
    },
  };

  const [filter, setFilter] = useState<any>(filtersInitialValues);

  // const analyticsClientsIncome = AnalyticsService.GetClientIncome({
  //   ...filter.date,
  //   ...(filter.date.date_from === null
  //     ? {}
  //     : {
  //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
  //       }),
  //   ...(filter.date.date_to === null
  //     ? {}
  //     : {
  //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
  //       }),
  // });

  const analyticsClientsIncome = AnalyticsService.GetClientIncome({
    ...filter.date,
    date_from: filter.date.date_from
      ? moment(filter.date.date_from).format("YYYY-MM")
      : null,
    date_to: filter.date.date_to
      ? moment(filter.date.date_to).format("YYYY-MM")
      : null,
  });

  // const analyticsClientsExpense = AnalyticsService.GetClientExpense({
  //   ...filter.date,
  //   ...(filter.date.date_from === null
  //     ? {}
  //     : {
  //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
  //       }),
  //   ...(filter.date.date_to === null
  //     ? {}
  //     : {
  //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
  //       }),
  // });

  const analyticsClientsExpense = AnalyticsService.GetClientExpense({
    ...filter.date,
    date_from: filter.date.date_from
      ? moment(filter.date.date_from).format("YYYY-MM")
      : null,
    date_to: filter.date.date_to
      ? moment(filter.date.date_to).format("YYYY-MM")
      : null,
  });

  // const analyticsClientsNetprofit = AnalyticsService.GetClientNetprofit({
  //   ...filter.date,
  //   ...(filter.date.date_from === null
  //     ? {}
  //     : {
  //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
  //       }),
  //   ...(filter.date.date_to === null
  //     ? {}
  //     : {
  //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
  //       }),
  // });

  const analyticsClientsNetprofit = AnalyticsService.GetClientNetprofit({
    ...filter.date,
    date_from: filter.date.date_from
      ? moment(filter.date.date_from).format("YYYY-MM")
      : null,
    date_to: filter.date.date_to
      ? moment(filter.date.date_to).format("YYYY-MM")
      : null,
  });

  // const analyticsManagerSalary = GraphicsService.GetGraphicsSalaryList({
  //   ...filter.date,
  //   ...(filter.date.date_from === null
  //     ? {}
  //     : {
  //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
  //       }),
  //   ...(filter.date.date_to === null
  //     ? {}
  //     : {
  //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
  //       }),
  // });

  const analyticsManagerSalary = GraphicsService.GetGraphicsSalaryList({
    ...filter.date,
    date_from: filter.date.date_from
      ? moment(filter.date.date_from).format("YYYY-MM")
      : null,
    date_to: filter.date.date_to
      ? moment(filter.date.date_to).format("YYYY-MM")
      : null,
  });

  // const analyticsManagerPrecentageSum = GraphicsService.GetGraphicsManagerList({
  //   ...filter.date,
  //   ...(filter.date.date_from === null
  //     ? {}
  //     : {
  //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
  //       }),
  //   ...(filter.date.date_to === null
  //     ? {}
  //     : {
  //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
  //       }),
  // });

  const analyticsManagerPrecentageSum = GraphicsService.GetGraphicsManagerList({
    ...filter.date,
    date_from: filter.date.date_from
      ? moment(filter.date.date_from).format("YYYY-MM")
      : null,
    date_to: filter.date.date_to
      ? moment(filter.date.date_to).format("YYYY-MM")
      : null,
  });

  // const analyticsClientsList = GraphicsService.GetGraphicsClientsList({
  //   ...filter.date,
  //   ...(filter.date.date_from === null
  //     ? {}
  //     : {
  //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
  //       }),
  //   ...(filter.date.date_to === null
  //     ? {}
  //     : {
  //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
  //       }),
  // });

  const analyticsClientsList = GraphicsService.GetGraphicsClientsList({
    ...filter.date,
    date_from: filter.date.date_from
      ? moment(filter.date.date_from).format("YYYY-MM")
      : null,
    date_to: filter.date.date_to
      ? moment(filter.date.date_to).format("YYYY-MM")
      : null,
  });


    const datasetColors = ["#576ED0", "#FF0000", "#C3EA4E"]
  // const datasetColors = new Array(
  //   analyticsClientsList?.result?.data?.data?.length || 0
  // )
  //   .fill(null)
  //   .map(() => randomColor());

  return (
    <>
      <Header pageTitle={"Графики"} />

      <div className="mt-[103px] w-full">
        <h1 className="text-[32px] font-semibold">Общая статистика</h1>

        <div className="flex items-center gap-[25px] my-[60px]">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              className="w-[129px]"
              value={filter.date.date_from}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  date: {
                    ...filter.date,
                    date_from: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                },
              }}
              views={["year", "month"]}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />

            <DatePicker
              className="w-[129px]"
              value={filter.date.date_to}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  date: {
                    ...filter.date,
                    date_to: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                },
              }}
              views={["year", "month"]}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />
          </LocalizationProvider>
        </div>

        <div className="border-[1px] rounded-[10px] border-[#6E6E68] px-5 py-10">
          {analyticsClientsIncome && (
            <Line
              data={{
                labels:
                  analyticsClientsIncome?.result?.data?.chart_data?.map(
                    (data: any) =>
                      moment(data.label).format(
                        analyticsClientsIncome?.result?.data.month
                          ? "DD/YYYY"
                          : "DD/MM/YYYY"
                      )
                  ) || [],

                datasets: [
                  {
                    label: "Доход",

                    data:
                      analyticsClientsIncome?.result?.data?.chart_data?.map(
                        (data: any) => data.data
                      ) || [],
                    fill: false,
                    borderColor: "#576ED0",
                    backgroundColor: "#576ED0",
                  },

                  {
                    label: "Расходы",
                    data:
                      analyticsClientsExpense?.result?.data?.chart_data?.map(
                        (data: any) => data.data
                      ) || [],
                    borderColor: "#FF0000",
                    backgroundColor: "#FF0000",
                  },

                  {
                    label: "Чистая прибыль",
                    data:
                      analyticsClientsNetprofit?.result?.data?.chart_data?.map(
                        (data: any) => data.data
                      ) || [],
                    borderColor: "#C3EA4E",
                    backgroundColor: "#C3EA4E",
                  },
                ],
              }}
              options={{
                scales: {
                  x: {
                    title: {
                      display: true,
                    },
                  },
                  y: {
                    title: {
                      display: true,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      padding: 12,
                      boxWidth: 12,
                      boxHeight: 12,
                      font: {
                        size: 12,
                      },
                      color: "#000",
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>

      <div className="mt-[103px] w-full">
        <h1 className="text-[32px] font-semibold">Количество клиентов</h1>

        <div className="flex items-center gap-[25px] my-[60px]">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              className="w-[129px]"
              value={filter.date.date_from}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  date: {
                    ...filter.date,
                    date_from: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                },
              }}
              views={["year", "month"]}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />

            <DatePicker
              className="w-[129px]"
              value={filter.date.date_to}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  date: {
                    ...filter.date,
                    date_to: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                },
              }}
              views={["year", "month"]}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />
          </LocalizationProvider>
        </div>

        <div className="border-[1px] rounded-[10px] border-[#6E6E68] px-5 py-10">
          {analyticsClientsList && (
            <Line
              data={{
                labels:
                  analyticsClientsList?.result?.data?.data?.[0]?.chart_data?.map(
                    (data: any) =>
                      moment(data.label).format(
                        analyticsClientsList?.result?.data.month
                          ? "DD/YYYY"
                          : "DD/MM/YYYY"
                      )
                  ) || [],

                datasets:
                  analyticsClientsList?.result?.data?.data?.map(
                    (item: any, index: number) => ({
                      label: item?.manager?.full_name || "",
                      data:
                        item.chart_data?.map((data: any) => data?.data) || [],
                      fill: false,
                      borderColor: datasetColors[index],
                      backgroundColor: datasetColors[index],
                    })
                  ) || [],
              }}
              options={{
                scales: {
                  x: {
                    title: {
                      display: true,
                    },
                  },
                  y: {
                    title: {
                      display: true,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      padding: 12,
                      boxWidth: 12,
                      boxHeight: 12,
                      font: {
                        size: 12,
                      },
                      color: "#000",
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>

      <div className="mt-[103px] w-full">
        <h1 className="text-[32px] font-semibold">Сумма зарплаты</h1>

        <div className="flex items-center gap-[25px] my-[60px]">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              className="w-[129px]"
              value={filter.date.date_from}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  date: {
                    ...filter.date,
                    date_from: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                },
              }}
              views={["year", "month"]}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />

            <DatePicker
              className="w-[129px]"
              value={filter.date.date_to}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  date: {
                    ...filter.date,
                    date_to: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                },
              }}
              views={["year", "month"]}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />
          </LocalizationProvider>
        </div>

        <div className="border-[1px] rounded-[10px] border-[#6E6E68] px-5 py-10">
          {analyticsManagerSalary && (
            <Line
              data={{
                labels:
                  analyticsManagerSalary?.result?.data?.manager_data?.[0]?.chart_data?.map(
                    (data: any) =>
                      moment(data.label).format(
                        analyticsManagerSalary?.result?.data.month
                          ? "DD/YYYY"
                          : "DD/MM/YYYY"
                      )
                  ) || [],
                datasets:
                  analyticsManagerSalary?.result?.data?.manager_data?.map(
                    (item: any, index: number) => ({
                      label: item.manager?.full_name || "",
                      data:
                        item.chart_data?.map((data: any) => data.data) || [],
                      fill: false,
                      borderColor: datasetColors[index],
                      backgroundColor: datasetColors[index],
                    })
                  ) || [],
              }}
              options={{
                scales: {
                  x: {
                    title: {
                      display: true,
                    },
                  },
                  y: {
                    title: {
                      display: true,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      padding: 12,
                      boxWidth: 12,
                      boxHeight: 12,
                      font: {
                        size: 12,
                      },
                      color: "#000",
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>

      <div className="mt-[103px] w-full">
        <h1 className="text-[32px] font-semibold">Процент менеджеров</h1>

        <div className="flex items-center gap-[25px] my-[60px]">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              className="w-[129px]"
              value={filter.date.date_from}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  date: {
                    ...filter.date,
                    date_from: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                },
              }}
              views={["year", "month"]}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />

            <DatePicker
              className="w-[129px]"
              value={filter.date.date_to}
              onChange={(newValue) => {
                setFilter({
                  ...filter,
                  date: {
                    ...filter.date,
                    date_to: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                },
              }}
              views={["year", "month"]}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />
          </LocalizationProvider>
        </div>

        <div className="border-[1px] rounded-[10px] border-[#6E6E68] px-5 py-10">
          {analyticsManagerPrecentageSum && (
            <Line
              data={{
                labels:
                  analyticsManagerPrecentageSum?.result?.data?.data?.[0]?.chart_data?.map(
                    (data: any) =>
                      moment(data.label).format(
                        analyticsManagerPrecentageSum?.result?.data.month
                          ? "DD/YYYY"
                          : "DD/MM/YYYY"
                      )
                  ) || [],
                datasets:
                  analyticsManagerPrecentageSum?.result?.data?.data?.map(
                    (item: any, index: number) => ({
                      label: item.manager?.full_name || "",
                      data:
                        item.chart_data?.map((data: any) => data.data) || [],
                      fill: false,
                      borderColor: datasetColors[index],
                      backgroundColor: datasetColors[index],
                    })
                  ) || [],
              }}
              options={{
                scales: {
                  x: {
                    title: {
                      display: true,
                    },
                  },
                  y: {
                    title: {
                      display: true,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      padding: 12,
                      boxWidth: 12,
                      boxHeight: 12,
                      font: {
                        size: 12,
                      },
                      color: "#000",
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
