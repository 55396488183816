import React, { useEffect, useState } from 'react'
import Header from '../components/UserHeader'
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { PaymentHistoryService } from '../service/PaymentHistoryService'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { checkModalResponse } from '../helpers/helpers'
import { ClientService } from '../service/ClientService'
import { StaffService } from '../service/StaffService'
import { ExpenseService } from '../service/ExpenseService'
import { useSnackbar } from '../helpers/SnackbarProvider'
import { DatePicker } from '@mui/x-date-pickers'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ru } from 'date-fns/locale/ru'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

export const PaymentHistory = () => {
    const navigate = useNavigate()
    const { openSnackbar } = useSnackbar()
    const user = useSelector((state: any) => state.userData.user.position.name)

    const tableInitialValues = {
        rows: [],
        status: {
            loading: false,
            error: false,
            message: '',
        },
        filter: {
            page: 1,
            size: 20,
            total_pages: 1,
            type: '',
            manager: '',
            services: '',
            date: '',
        },
        columns: [
            {
                field: 'index',
                headerName: '№',
                width: 90,
                renderCell: (params: any) =>
                    params.api.getAllRowIds().indexOf(params.id) + 1,
            },

            ...(user !== 'Менеджер'
                ? [
                      {
                          field: 'manager',
                          headerName: 'Менеджер',
                          flex: 1,
                          renderCell: (params: any) =>
                              params.row.manager?.full_name,
                      },
                  ]
                : []),

            {
                field: 'client',
                headerName: 'Клиент',
                flex: 1,
                renderCell: (params: any) => params.row.client?.full_name,
            },
            {
                field: 'services',
                headerName: 'Услуги',
                flex: 1,
                renderCell: (params: any) => params.row.services?.title,
            },
            {
                field: 'amount_sum',
                headerName: 'Сумма',
                flex: 1,
                renderCell: (params: any) =>
                    user === 'Помощник бухгалтера'
                        ? params.row.amount
                        : Number.isInteger(params.row.amount_sum)
                        ? params.row.amount_sum
                        : params.row.amount_sum.toFixed(2),
            },
            // {
            //   field: "percentage_of_cooperation_sum",
            //   headerName: "Процент от сотруд.",
            //   flex: 1,
            //   renderCell: (params: any) =>
            //     params.row.percentage_of_cooperation
            //       ? `${params.row.percentage_of_cooperation}%`
            //       : "нет данных",
            // },
            {
                field: 'percentage_of_the_manager_sum',
                headerName: 'Процент менеджера',
                flex: 1,
                renderCell: (params: any) =>
                    params.row.percentage_of_the_manager_sum
                        ? `${params.row.percentage_of_the_manager_sum.toFixed(
                              0,
                          )}`
                        : 0,
            },
            {
                field: 'tax_sum',
                headerName: 'Налог',
                flex: 1,
                renderCell: (params: any) => `${params.row.tax_sum.toFixed(0)}`,
            },
            {
                field: 'type_of_registration',
                headerName: 'Юр. оформление',
                flex: 1,
                renderCell: (params: any) =>
                    params.row.type_of_registration?.name,
            },
            {
                field: 'date',
                headerName: 'Дата',
                width: 90,
                renderCell: (params: any) =>
                    moment(params.row.date).format('DD.MM.YYYY').toString(),
            },
            {
                field: 'actions',
                headerName: 'Действия',
                width: 110,
                renderCell: (params: any) => (
                    <div className="flex justify-start gap-2">
                        <IconButton
                            onClick={() => {
                                setModal({
                                    ...modal,
                                    open: true,
                                    action: 'edit',
                                    values: {
                                        ...params.row,
                                        type_of_registration:
                                            params.row.type_of_registration?.id,
                                        type: params.row.type?.id,
                                        status: params.row.status?.id,
                                        services: params.row.services?.id,
                                        percentage_of_the_manager:
                                            params.row
                                                .percentage_of_the_manager,
                                        percentage_of_cooperation:
                                            params.row
                                                .percentage_of_cooperation,
                                        client: params.row.client?.id,
                                        manager: params.row.manager?.id,
                                        // date: moment(params?.row?.date, "DD-MM-YYYY").format(
                                        //   "DD-MM-YYYY"
                                        // ),
                                        date: params.row.date,
                                    },
                                })
                                setClient(params.row.client?.full_name)
                            }}
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setModal({
                                    ...modal,
                                    open: true,
                                    action: 'delete',
                                    values: {
                                        ...params.row,
                                        type_of_registration:
                                            params.row.type_of_registration?.id,
                                        type: params.row.type?.id,
                                        status: params.row.status?.id,
                                        percentage_of_the_manager:
                                            params.row
                                                .percentage_of_the_manager,
                                        percentage_of_cooperation:
                                            params.row
                                                .percentage_of_cooperation,
                                        client: params.row.client?.id,
                                        manager: params.row.manager?.id,
                                    },
                                })
                                setClient(params.row.client?.full_name)
                            }}
                        >
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
    }
    const [table, setTable] = useState<any>(tableInitialValues)

    const modalInitialValues: any = {
        open: false,
        values: {
            id: '',
            service: '',
            amount: '',
            tax: '',
            type: '',
            manager: '',
            client: '',
            percentage_of_the_manager: '',
            percentage_of_cooperation: '',
            type_of_registration: '',
            services: '',
            status: '',
            date: null,
        },
        validation: {
            error: {
                service: false,
                amount: false,
                tax: false,
                type: false,
                manager: false,
                client: false,
                services: false,
                percentage_of_the_manager: false,
                percentage_of_cooperation: false,
                type_of_registration: false,
                status: false,
                date: false,
            },
            message: {
                service: '',
                amount: '',
                tax: '',
                type: '',
                manager: '',
                client: '',
                services: '',
                percentage_of_the_manager: '',
                percentage_of_cooperation: '',
                type_of_registration: '',
                status: '',
                date: '',
            },
        },
        requested: false,
        action: '',
    }
    const [modal, setModal] = useState({
        ...modalInitialValues,
        values: {
            ...modalInitialValues.values,
            type: table.filter.type,
        },
    })

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        setModal({
            ...modal,
            requested: true,
        })

        switch (modal.action) {
            case 'add':
                PaymentHistoryService.CreatePaymentHistory(modal.values)
                    .then(() => {

                        if (modal.values.client === "") {
                            setModal({
                                ...modal,
                                requested: false,
                            })
                            return openSnackbar("Выберите клиента", {variant: "error"})
                        }
                        setModal(modalInitialValues)
                        setClient("")
                        tableList.execute()
                        openSnackbar('Успешно добавлено', {
                            variant: 'success',
                        })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setModal, modal)
                        openSnackbar('Не удалось добавить', {
                            variant: 'error',
                        })
                    })
                break

            case 'edit':
                PaymentHistoryService.UpdatePaymentHistory(modal.values)
                    .then(() => {
                        setModal(modalInitialValues)
                        setClient("")
                        tableList.execute()
                        openSnackbar('Успешно изменено', { variant: 'success' })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setModal, modal)
                        openSnackbar('Не удалось изменить', {
                            variant: 'error',
                        })
                    })

                break

            case 'delete':
                PaymentHistoryService.DeletePaymentHistory(modal.values)
                    .then(() => {
                        setModal(modalInitialValues)
                        setClient("")
                        tableList.execute()
                        openSnackbar('Успешно удалено', { variant: 'success' })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setModal, modal)
                        openSnackbar('Не удалось удалить', { variant: 'error' })
                    })
                break

            default:
                break
        }
    }

    const tableList = PaymentHistoryService.GetPaymentHistoryList(table.filter)

    const paymentHistoryTypes = PaymentHistoryService.GetPaymentHistoryTypes()

    const percentageOfCooperation =
        PaymentHistoryService.GetPaymentHistoryCooperationPercentage()

    const percentageOfManager =
        PaymentHistoryService.GetPaymentHistoryManagerPercentage()

    const registrationTypes = ExpenseService.GetRegistrationTypes()

    const managers = StaffService.GetManagers()

    const paymentHistoryStatuses =
        PaymentHistoryService.GetPaymentHistoryStatuses()

    const [client, setClient] = useState('')
    const clientList = ClientService.GetAllClientList()

    const serviceList = ClientService.GetServices()

    const changeTableColumns = (type: any) => {
        const newColumn =
            type.slug === 'partner'
                ? {
                      field: 'percentage_of_cooperation_sum',
                      headerName: 'Процент от сотруд.',
                      flex: 1,
                      renderCell: (params: any) =>
                          `${
                              params.row.percentage_of_cooperation_sum
                                  ? params.row.percentage_of_cooperation_sum.toFixed(
                                        0,
                                    )
                                  : 0
                          }`,
                  }
                : null

        setTable({
            ...table,
            filter: {
                ...table.filter,
                type: type.id,
            },
            columns: [
                ...tableInitialValues.columns.slice(0, 5),
                ...(newColumn ? [newColumn] : []),
                ...tableInitialValues.columns.slice(5),
            ],
        })

        setModal({
            ...modal,
            values: {
                ...modal.values,
                type: type.id,
            },
        })
    }

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }))
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }))
        } else {
            const data = tableList.result?.data?.results
            const dataParams = tableList.result?.data

            setTable((prevState: any) => ({
                ...prevState,
                rows: data,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: dataParams.current_page,
                    total_pages: dataParams.total_pages,
                },
            }))
        }
    }, [tableList.loading, tableList.error, tableList.result?.data])

    useEffect(() => {
        if (
            !paymentHistoryTypes.loading &&
            !paymentHistoryTypes.error &&
            paymentHistoryTypes.result?.data.length > 0
        ) {
            const latestType =
                paymentHistoryTypes.result?.data[
                    0
                ]

            const newColumn =
                latestType.slug === 'partner'
                    ? {
                          field: 'percentage_of_cooperation_sum',
                          headerName: 'Процент от сотруд.',
                          flex: 1,
                          renderCell: (params: any) =>
                              params.row.percentage_of_cooperation_sum
                                  ? `${params.row.percentage_of_cooperation_sum}`
                                  : 0,
                      }
                    : null

            setTable({
                ...table,
                filter: {
                    ...table.filter,
                    type: latestType.id,
                },
                columns: [
                    tableInitialValues.columns[0],
                    ...(newColumn ? [newColumn] : []),
                    ...tableInitialValues.columns.slice(1),
                ],
            })

            setModal({
                ...modal,
                values: {
                    ...modal.values,
                    type: latestType.id,
                },
            })
        }
    }, [
        paymentHistoryTypes.loading,
        paymentHistoryTypes.error,
        paymentHistoryTypes.result,
    ])

    useEffect(() => {
        if (!paymentHistoryStatuses.loading && !paymentHistoryStatuses.error && !table.columns.some((elem: any) => elem.field === "status")) {
            setTable({
                ...table,
                columns: [
                    ...table.columns,
                    {
                        field: 'status',
                        headerName: 'Статус',
                        flex: 1.3,
                        renderCell: (params: any) => {
                            const isAdmin = user === 'Администратор'
                            const currentStatusId = params.row.status?.id

                            if (!isAdmin) {
                                return (
                                    <div className="text-[10px]">
                                        {params.row.status?.name}
                                    </div>
                                )
                            }

                            return (
                                <Select
                                    className="status-select"
                                    placeholder="Статус"
                                    value={currentStatusId || ''}
                                    onChange={(event: any) => {
                                        PaymentHistoryService.PartialUpdatePaymentHistory(
                                            {
                                                id: params.row.id,
                                                status: event.target.value,
                                            },
                                        ).then(() => tableList.execute())
                                    }}
                                >
                                    {!paymentHistoryStatuses.loading &&
                                        !paymentHistoryStatuses.error &&
                                        paymentHistoryStatuses.result?.data.map(
                                            (status: any, index: number) => (
                                                <MenuItem
                                                    key={index}
                                                    value={status.id}
                                                >
                                                    {status?.name}
                                                </MenuItem>
                                            ),
                                        )}
                                </Select>
                            )
                        },
                    },
                ],
            })
        }
    }, [paymentHistoryStatuses.loading, paymentHistoryStatuses.error, table.filter.type])

    return (
        <>
            <Header pageTitle={'История платежей'} />

            <div className="w-full flex justify-between items-center mt-[103px] mb-[40px]">
                <div className="flex items-end gap-[20px]">
                    {!paymentHistoryTypes.loading &&
                        !paymentHistoryTypes.error &&
                        paymentHistoryTypes.result?.data.map(
                            (type: any, index: number) => (
                                <Button
                                    variant={
                                        type.id === table.filter.type
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    color="black"
                                    key={index}
                                    sx={{ borderRadius: '100px' }}
                                    onClick={() => changeTableColumns(type)}
                                >
                                    {type.name}
                                </Button>
                            ),
                        )}

                    <FormControl className="w-[120px]" variant="standard">
                        <InputLabel>Услуги</InputLabel>
                        <Select
                            label="Услуги"
                            required={true}
                            value={table.filter.services}
                            onChange={(event: any) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        services: event.target.value,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {!serviceList.loading &&
                                !serviceList.error &&
                                serviceList.result?.data.map(
                                    (group: any, index: number) => (
                                        <MenuItem key={index} value={group.id}>
                                            {group.title}
                                        </MenuItem>
                                    ),
                                )}
                        </Select>
                    </FormControl>

                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DatePicker
                            className="w-[169px]"
                            label="Дата"
                            onChange={(newValue: any) => {
                                let formattedDate = ''
                                if (newValue) {
                                    formattedDate =
                                        moment(newValue).format('YYYY-MM')
                                }
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        date: formattedDate,
                                    },
                                })
                            }}
                            slotProps={{
                                textField: { variant: 'standard' },
                                field: { clearable: true },
                                // actionBar: {
                                //     actions: ['clear'],
                                // },
                            }}
                            views={['year', 'month']}
                            minDate={new Date('2000-01-01')}
                            maxDate={new Date('2050-12-31')}
                        />
                    </LocalizationProvider>

                    {user !== 'Менеджер' && user !== "Помощник бухгалтера" && (
                        <FormControl className="w-[120px]" variant="standard">
                            <InputLabel>Менеджер</InputLabel>
                            <Select
                                label="Менеджер"
                                required={true}
                                value={table.filter.manager}
                                onChange={(event: any) => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            manager: event.target.value,
                                        },
                                    })
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {!managers.loading &&
                                    !managers.error &&
                                    managers.result?.data.map(
                                        (group: any, index: number) => (
                                            <MenuItem
                                                key={index}
                                                value={group.id}
                                            >
                                                {group.full_name}
                                            </MenuItem>
                                        ),
                                    )}
                            </Select>
                        </FormControl>
                    )}
                </div>

                <div>
                    <Button
                        variant="contained"
                        color="black"
                        style={{
                            borderRadius: '100px',
                            padding: '16px 30px',
                            lineHeight: 'normal',
                        }}
                        onClick={() =>
                            setModal({ ...modal, open: true, action: 'add' })
                        }
                    >
                        Добавить платеж
                    </Button>
                </div>
            </div>

            <Box sx={{ width: '100%', marginBottom: '40px' }}>
                <DataGrid
                    rows={table.rows}
                    columns={table.columns}
                    checkboxSelection={false}
                    keepNonExistentRowsSelected
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    loading={table.status.loading}
                    disableRowSelectionOnClick
                    onRowDoubleClick={(params: any) => {
                        if (params.row.client && params.row.client.id) {
                            navigate(`/clients/${params.row.client.id}`)
                        } else {
                            openSnackbar("У записи нет клиента", {variant: "error"})
                        }
                    }}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#979CFF',
                            color: '#FFF',
                        },
                        '.MuiDataGrid-iconButtonContainer': {
                            display: 'none',
                        },
                    }}
                />
            </Box>

            <div className="flex w-full justify-center items-start">
                <div className="flex w-full">
                    <div className="flex gap-[70px] py-[20px] px-[30px]  border-[1px] border-[#D4D4D4] rounded-[10px]">
                        <div>
                            <h5 className="text-[14px] border-b-[2px] border-[#282828] pb-[5px] mb-1 font-semibold">
                                Общая сумма
                            </h5>
                            <span className="text-[14px]">
                                {tableList.result?.data?.total_sum.toFixed(0)}
                            </span>
                        </div>
                        <div>
                            <h5 className="text-[14px]  border-b-[2px] border-[#282828] pb-[5px] mb-1 font-semibold">
                                Налог
                            </h5>
                            <span className="text-[14px]">
                                {tableList.result?.data?.total_tax.toFixed(0)}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="w-full flex gap-[20px] items-center justify-end">
                    <div className="flex items-center gap-[10px]">
                        <p>Rows:</p>
                        <TextField
                            label=""
                            variant="outlined"
                            size="small"
                            type="number"
                            sx={{ width: '100px' }}
                            value={table.filter.size}
                            onChange={(event) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        size: event.target.value,
                                    },
                                })
                            }}
                        />
                    </div>
                    <Pagination
                        count={table.filter.total_pages}
                        page={table.filter.page}
                        onChange={(event, value: number) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    page: value,
                                },
                            })
                        }}
                    />
                </div>
            </div>

            <Modal
                open={modal.open}
                onClose={() => {
                    setModal(modalInitialValues)
                    setClient("")
                }}
            >
                <form
                    onSubmit={handleFormSubmit}
                    className="mainModal relative flex flex-col justify-start items-center"
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => {
                            setModal(modalInitialValues)
                            setClient("")
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[40px]">
                        {modal.action === 'add' && 'Добавить платеж'}
                        {modal.action === 'edit' && 'Редактировать платеж'}
                        {modal.action === 'delete' && 'Удалить платеж?'}
                    </h2>
                    {modal.action !== 'delete' && (
                        <div className="w-full grid grid-cols-2 gap-[30px] mb-[50px]">
                            <FormControl variant="standard">
                                <InputLabel>Тип платежа</InputLabel>
                                <Select
                                    label="Тип платежа"
                                    required
                                    value={modal.values.type}
                                    error={modal.validation.error.type}
                                    onChange={(event: any) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                type: event.target.value,
                                            },
                                        })
                                    }}
                                >
                                    {/* <MenuItem value=""><em>None</em></MenuItem> */}
                                    {!paymentHistoryTypes.loading &&
                                        !paymentHistoryTypes.error &&
                                        paymentHistoryTypes.result?.data.map(
                                            (type: any, index: number) => (
                                                <MenuItem
                                                    key={index}
                                                    value={type.id}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            ),
                                        )}
                                </Select>
                                <FormHelperText>
                                    {modal.validation.message.type}
                                </FormHelperText>
                            </FormControl>

                            <Autocomplete
                                freeSolo
                                isOptionEqualToValue={(option: any, value) =>
                                    option.full_name === value.full_name
                                }
                                getOptionLabel={(option: any) =>
                                    option.full_name
                                }
                                options={
                                    !clientList.loading && !clientList.error
                                        ? clientList.result?.data.filter(
                                              (option: any) =>
                                                  option.status.slug !==
                                                  'not_activate',
                                          )
                                        : []
                                }
                                loading={clientList.loading}
                                onChange={(event: any, newValue: any) => {
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            client: newValue.id,
                                            service: newValue.service,
                                        },
                                    })
                                }}
                                inputValue={client}
                                onInputChange={(event, newInputValue) => {
                                    setClient(newInputValue)
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            client: "",
                                        },
                                    })
                                }}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Клиент"
                                        required
                                        variant="standard"
                                    />
                                )}
                            />

                            {user !== 'Менеджер' && user !== "Помощник бухгалтера" && (
                                <FormControl variant="standard">
                                    <InputLabel>Менеджер</InputLabel>
                                    <Select
                                        label="Мен еджер"
                                        required
                                        value={modal.values.manager}
                                        error={modal.validation.error.manager}
                                        onChange={(event: any) => {
                                            setModal({
                                                ...modal,
                                                values: {
                                                    ...modal.values,
                                                    manager: event.target.value,
                                                },
                                            })
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {!managers.loading &&
                                            !managers.error &&
                                            managers.result?.data.map(
                                                (
                                                    manager: any,
                                                    index: number,
                                                ) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={manager.id}
                                                    >
                                                        {manager.full_name}
                                                    </MenuItem>
                                                ),
                                            )}
                                    </Select>
                                    <FormHelperText>
                                        {modal.validation.message.manager}
                                    </FormHelperText>
                                </FormControl>
                            )}

                            {!paymentHistoryTypes.loading &&
                                !paymentHistoryTypes.error &&
                                (() => {
                                    const selectedType =
                                        paymentHistoryTypes.result?.data.find(
                                            (type: any) =>
                                                type.id === modal.values.type,
                                        )
                                    if (selectedType) {
                                        if (selectedType.slug === 'partner') {
                                            return (
                                                <FormControl variant="standard">
                                                    <Autocomplete
                                                        freeSolo
                                                        value={
                                                            modal.values
                                                                .percentage_of_cooperation
                                                        }
                                                        onChange={(
                                                            event,
                                                            value,
                                                        ) => {
                                                            setModal({
                                                                ...modal,
                                                                values: {
                                                                    ...modal.values,
                                                                    percentage_of_cooperation:
                                                                        value
                                                                            ? value
                                                                            : '',
                                                                },
                                                            })
                                                        }}
                                                        renderInput={(
                                                            params,
                                                        ) => (
                                                            <TextField
                                                                required
                                                                value={
                                                                    modal.values
                                                                        .percentage_of_cooperation
                                                                }
                                                                error={
                                                                    modal
                                                                        .validation
                                                                        .error
                                                                        .percentage_of_cooperation
                                                                }
                                                                label="Процент от сотрудничества"
                                                                {...params}
                                                                variant="standard"
                                                                onChange={(
                                                                    event: any,
                                                                ) => {
                                                                    setModal({
                                                                        ...modal,
                                                                        values: {
                                                                            ...modal.values,
                                                                            percentage_of_cooperation:
                                                                                event
                                                                                    .target
                                                                                    .value,
                                                                        },
                                                                    })
                                                                }}
                                                            />
                                                        )}
                                                        options={
                                                            !percentageOfCooperation.loading &&
                                                            !percentageOfCooperation.error &&
                                                            percentageOfCooperation.result?.data.map(
                                                                (
                                                                    item: any,
                                                                    index: number,
                                                                ) => item.value,
                                                            )
                                                        }
                                                        getOptionLabel={(
                                                            option: any,
                                                        ) => `${option}`}
                                                        renderOption={(
                                                            props,
                                                            option,
                                                        ) => (
                                                            <li
                                                                {...props}
                                                            >{`${option}%`}</li>
                                                        )}
                                                    />
                                                    <FormHelperText>
                                                        {
                                                            modal.validation
                                                                .message
                                                                .percentage_of_cooperation
                                                        }
                                                    </FormHelperText>
                                                </FormControl>
                                            )
                                        }
                                    }
                                })()}
                            <FormControl variant="standard">
                                <Autocomplete
                                    freeSolo
                                    value={
                                        modal.values.percentage_of_the_manager
                                    }
                                    onChange={(event, value) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                percentage_of_the_manager: value
                                                    ? value
                                                    : '',
                                            },
                                        })
                                    }}
                                    options={
                                        !percentageOfManager.loading &&
                                        !percentageOfManager.error
                                            ? percentageOfManager.result?.data.map(
                                                  (item: any, index: number) =>
                                                      item.value,
                                              )
                                            : []
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            value={
                                                modal.values
                                                    .percentage_of_the_manager
                                            }
                                            error={
                                                modal.validation.error
                                                    .percentage_of_the_manager
                                            }
                                            label="Процент менеджера"
                                            variant="standard"
                                            required
                                            {...params}
                                            onChange={(event: any) => {
                                                setModal({
                                                    ...modal,
                                                    values: {
                                                        ...modal.values,
                                                        percentage_of_the_manager:
                                                            event.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    )}
                                    getOptionLabel={(option: any) =>
                                        `${option}`
                                    }
                                    renderOption={(props, option) => (
                                        <li {...props}>{`${option}%`}</li>
                                    )}
                                />
                                <FormHelperText>
                                    {
                                        modal.validation.message
                                            .percentage_of_the_manager
                                    }
                                </FormHelperText>
                            </FormControl>
                            <FormControl variant="standard">
                                <InputLabel>Тип регистрации</InputLabel>
                                <Select
                                    label="Тип регистрации"
                                    required
                                    value={modal.values.type_of_registration}
                                    error={
                                        modal.validation.error
                                            .type_of_registration
                                    }
                                    onChange={(event: any) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                type_of_registration:
                                                    event.target.value,
                                            },
                                        })
                                    }}
                                >
                                    {/* <MenuItem value=""><em>None</em></MenuItem> */}
                                    {!registrationTypes.loading &&
                                        !registrationTypes.error &&
                                        registrationTypes.result?.data.map(
                                            (
                                                registration: any,
                                                index: number,
                                            ) => (
                                                <MenuItem
                                                    key={index}
                                                    value={registration.id}
                                                >
                                                    {registration.name}
                                                </MenuItem>
                                            ),
                                        )}
                                </Select>
                                <FormHelperText>
                                    {
                                        modal.validation.message
                                            .type_of_registration
                                    }
                                </FormHelperText>
                            </FormControl>
                            <TextField
                                fullWidth
                                required
                                label="Сумма"
                                variant="standard"
                                type={'number'}
                                value={modal.values.amount}
                                error={modal.validation.error.amount}
                                helperText={modal.validation.message.amount}
                                onChange={(e) => {
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            amount: e.target.value,
                                        },
                                    })
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Налог (%)"
                                variant="standard"
                                type={'number'}
                                value={modal.values.tax}
                                error={modal.validation.error.tax}
                                helperText={modal.validation.message.tax}
                                onChange={(e) => {
                                    setModal({
                                        ...modal,
                                        values: {
                                            ...modal.values,
                                            tax: e.target.value,
                                        },
                                    })
                                }}
                            />

                            {user === 'Администратор' && (
                                <FormControl variant="standard">
                                    <InputLabel>Статус</InputLabel>
                                    <Select
                                        label="Статус"
                                        required
                                        value={modal.values.status}
                                        error={modal.validation.error.status}
                                        onChange={(event: any) => {
                                            setModal({
                                                ...modal,
                                                values: {
                                                    ...modal.values,
                                                    status: event.target.value,
                                                },
                                            })
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {!paymentHistoryStatuses.loading &&
                                            !paymentHistoryStatuses.error &&
                                            paymentHistoryStatuses.result?.data.map(
                                                (
                                                    status: any,
                                                    index: number,
                                                ) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={status.id}
                                                    >
                                                        {status.name}
                                                    </MenuItem>
                                                ),
                                            )}
                                    </Select>
                                    <FormHelperText>
                                        {modal.validation.message.status}
                                    </FormHelperText>
                                </FormControl>
                            )}
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={ru}
                            >
                                <DatePicker
                                    className="w-full"
                                    label="Дата"
                                    value={modal.values.date}
                                    onChange={(newValue: any) => {
                                        if (newValue) {
                                            const formattedDate =
                                                moment(newValue).format(
                                                    'YYYY-MM-DD',
                                                )
                                            setModal({
                                                ...modal,
                                                values: {
                                                    ...modal.values,
                                                    date: formattedDate,
                                                },
                                            })
                                        }
                                    }}
                                    slotProps={{
                                        textField: { variant: 'standard' },
                                    }}
                                    minDate={new Date('2000-01-01')}
                                    maxDate={new Date('2050-12-31')}
                                />
                            </LocalizationProvider>

                            <FormControl className="w-full" variant="standard">
                                <InputLabel>Услуга</InputLabel>
                                <Select
                                    label="Услуга"
                                    required={true}
                                    value={modal.values.services}
                                    error={modal.validation.error.services}
                                    onChange={(event: any) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                services: event.target.value,
                                            },
                                        })
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {!serviceList.loading &&
                                        !serviceList.error &&
                                        serviceList.result?.data.map(
                                            (group: any, index: number) => (
                                                <MenuItem
                                                    key={index}
                                                    value={group.id}
                                                >
                                                    {group.title}
                                                </MenuItem>
                                            ),
                                        )}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <div className="w-full flex gap-[20px]">
                        <LoadingButton
                            style={{ borderRadius: '100px' }}
                            fullWidth
                            variant="outlined"
                            color="black"
                            onClick={() => {
                                setModal(modalInitialValues)
                                setClient("")
                            }}
                        >
                            Отменить
                        </LoadingButton>
                        <LoadingButton
                            style={{ borderRadius: '100px' }}
                            loading={modal.requested}
                            fullWidth
                            variant="contained"
                            color="black"
                            type="submit"
                        >
                            {modal.action === 'delete' && 'Удалить'}
                            {modal.action === 'edit' && 'Готово'}
                            {modal.action === 'add' && 'Добавить'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal>
        </>
    )
}
